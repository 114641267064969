<template>
    <div>
        <div class="ZAfont_family ZAfont_size">{{$t('1447')}}</div>
    </div>
</template>

<script>
    export default {
        name: 'InvalidInputs',
    }
</script>
